import {
  Button,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { InfoOutlined } from "@mui/icons-material";
import Avatar from "components/@extended/Avatar";

export const CellResourceName: FC<{
  title: string;
  subtitle?: string;
  logo?: string;
  viewLink?: string;
  withLogo?: boolean;
  children?: ReactNode;
  resource?: "vehicule" | "personne" | "client";
}> = (props) => {
  const {
    logo,
    subtitle,
    title,
    viewLink,
    withLogo = true,
    resource = "personne",
  } = props;

  const renderLogo = () => {
    if (!withLogo) {
      return null;
    }
    return (
      <Avatar
        src={logo}
        resource={resource}
        sx={{ height: 50, width: 50 }}
        color="secondary"
      />
    );
  };

  const content = (
    <>
      <Typography
        color="textPrimary"
        variant="subtitle1"
        sx={{
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {title}
      </Typography>
      <Typography color="textSecondary" fontWeight={500} variant="caption">
        {subtitle}
      </Typography>
    </>
  );
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      {renderLogo()}
      {viewLink ? (
        <Stack
          spacing={0.25}
          component={Link}
          to={viewLink}
          sx={{
            textDecoration: "none",
          }}
          title="Ouvrir"
        >
          {content}
          {props.children}
        </Stack>
      ) : (
        <Stack spacing={0.25}>
          {content}
          {props.children}
        </Stack>
      )}
    </Stack>
  );
};

const Expanded: FC<{ row: any }> = ({ row }) => {
  const theme = useTheme();
  return (
    <IconButton
      onClick={(e: any) => {
        e.stopPropagation();
        row.toggleRowExpanded();
      }}
    >
      {row.isExpanded ? (
        <IconChevronUp style={{ color: theme.palette.text.primary }} />
      ) : (
        <IconChevronDown style={{ color: theme.palette.text.primary }} />
      )}
    </IconButton>
  );
};

export const CELL_EXPANDED = {
  title: "Row Selection",
  accessor: "selection",
  Cell: ({ row }: any) => <Expanded row={row} />,
  disableSortBy: true,
};

type RowActionsProps = {
  link?: {
    view: string;
    edit?: string;
  };
  children?: ReactNode;
};

export const CellAction: FC<RowActionsProps> = ({ link, children }) => {
  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="center" justifyContent="end" spacing={0}>
      {link && (
        <Button
          size="small"
          onClick={() => navigate(link.view)}
          variant="outlined"
        >
          Ouvrir
        </Button>
      )}
      {children}
    </Stack>
  );
};

type CellLinkProps = {
  title: string;
  link: string;
  help: string;
  name: string;
};

export const CellLink: FC<CellLinkProps> = ({ name, title, link, help }) => {
  return (
    <Tooltip title={help}>
      <Typography
        component={Link}
        to={link}
        color="textPrimary"
        variant="subtitle1"
        sx={{
          textDecoration: "none",
          ":hover": {
            textDecoration: "underline",
          },
        }}
      >
        {title}
      </Typography>
    </Tooltip>
  );
};

export const CellPopover: FC<{ name: string; children: ReactNode }> = ({
  name,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? name : undefined;

  return (
    <>
      <Tooltip title="Voir détails">
        <IconButton onClick={handleClick} color="primary">
          <InfoOutlined
            sx={{
              color: (theme) => theme.palette.secondary[50],
              fontSize: "1.25rem",
            }}
          />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // elevation={3}
        // PaperProps={{
        //   sx: {
        //     // border: 1,
        //     padding: 0,
        //     borderColor: (theme) => theme.palette.grey[300],
        //   },
        // }}
      >
        {children}
      </Popover>
    </>
  );
};
