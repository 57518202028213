// material-ui
import { Stack, Typography } from "@mui/material";

import Drapeau from "assets/images/rci.png";

export const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: "24px 16px 0px", mt: "auto" }}
  >
    <Typography variant="caption">&copy; 2022 Tous droits reservés</Typography>
    <Stack
      spacing={1.5}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="caption">BURVAL CORPORATE</Typography>
    </Stack>
    <p><img src={Drapeau} alt="Côte d'Ivoire" title="Côte d'Ivoire" style={{ width: "35px"}} /></p>
  </Stack>
);

export default Footer;
