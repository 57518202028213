import { configureStore } from "@reduxjs/toolkit";
import auth from "./reducers/auth";
import menu from "./reducers/menu";
import referentiel from "./reducers/referentiel";
import centre from "./reducers/centre";
import personnel from "./reducers/personnel";
import client from "./reducers/client";
import message from "./reducers/message";
import transport from "./reducers/transport";
import user from "./reducers/user";

export const store = configureStore({
  reducer: {
    auth,
    user,
    message,
    menu,
    referentiel,
    centre,
    personnel,
    client,
    transport,
  },
});

export type RootState = ReturnType<any>;
export type AppDispatch = typeof store.dispatch;
