import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterProps, ListPaginated } from "types/list.type";
import {
  DesserteItem,
  DesserteResource,
  TourneeItem,
  TourneeResource,
  VehiculeResource,
} from "types/transport.types";

type TransportState = {
  vehicules: ListPaginated<VehiculeResource>;
  vehiculeFilter: FilterProps;
  dessertes: ListPaginated<DesserteResource>;
  desserteFilter: FilterProps;
  tournees: ListPaginated<TourneeResource>;
  tourneeFilter: FilterProps;
  factures: ListPaginated<any>;
  factureFilter: FilterProps;
};

const INIT_LIST = {
  data: [],
  total: 0,
  totalPage: 0,
  page: 1,
  totalInDatabase: 0,
};

const INIT_FILTRE = {
  page: 1,
  perpage: 10,
};

const initialState: TransportState = {
  vehicules: { ...INIT_LIST },
  vehiculeFilter: { ...INIT_FILTRE },
  dessertes: { ...INIT_LIST },
  desserteFilter: { ...INIT_FILTRE },
  tournees: { ...INIT_LIST },
  tourneeFilter: { ...INIT_FILTRE },
  factures: { ...INIT_LIST },
  factureFilter: { ...INIT_FILTRE },
};

const transportSlice = createSlice({
  name: "transport",
  initialState,
  reducers: {
    setVehicules(
      state,
      action: PayloadAction<ListPaginated<VehiculeResource>>
    ) {
      state.vehicules = action.payload;
    },
    setVehiculeFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.vehiculeFilter = payload;
    },
    setDessertes(
      state,
      action: PayloadAction<ListPaginated<DesserteResource>>
    ) {
      state.dessertes = action.payload;
    },
    setDesserteFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.desserteFilter = payload;
    },
    updateDesserte(state, action: PayloadAction<DesserteItem>) {
      state.dessertes.data = state.dessertes.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },

    setTournees(state, action: PayloadAction<ListPaginated<TourneeItem>>) {
      state.tournees = action.payload;
    },
    updateTournee(state, action: PayloadAction<TourneeItem>) {
      state.tournees.data = state.tournees.data.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    setTourneeFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.tourneeFilter = payload;
    },

    setTransportFactures(state, action: PayloadAction<ListPaginated<any>>) {
      state.factures = action.payload;
    },
    setTransportFactureFilter(state, { payload }: PayloadAction<FilterProps>) {
      state.factureFilter = payload;
    },
  },
});

export default transportSlice.reducer;

export const {
  setVehicules,
  setVehiculeFilter,
  setDessertes,
  setDesserteFilter,
  updateDesserte,
  setTournees,
  setTourneeFilter,
  updateTournee,
  setTransportFactures,
  setTransportFactureFilter,
} = transportSlice.actions;

const getTransportReducer = (state: any): TransportState => state.transport;

export const selectVehicules = createSelector(
  getTransportReducer,
  ({ vehicules }) => vehicules
);

export const selectVehiculeFilter = createSelector(
  getTransportReducer,
  ({ vehiculeFilter }) => vehiculeFilter
);

export const selectDessertes = createSelector(
  getTransportReducer,
  ({ dessertes }) => dessertes
);

export const selectDesserteFilter = createSelector(
  getTransportReducer,
  ({ desserteFilter }) => desserteFilter
);

export const selectTournees = createSelector(
  getTransportReducer,
  ({ tournees }) => tournees
);

export const selectTourneeFilter = createSelector(
  getTransportReducer,
  ({ tourneeFilter }) => tourneeFilter
);

export const selectTransportFactures = createSelector(
  getTransportReducer,
  ({ factures }) => factures
);

export const selectTransportFactureFilter = createSelector(
  getTransportReducer,
  ({ factureFilter }) => factureFilter
);
