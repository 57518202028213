import { Grid } from "@mui/material";
import { DateInput, SelectPaysAsycForm, TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={
        props.isEditMode ? "Gestion des jours fériés" : "Ajouter un jour férié"
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DateInput label="Date *" name="date" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Libellé *" name="libelle" />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const FerieModal = withForm(Form);
