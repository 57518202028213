import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { dateFormater } from "utils/date";

type TrajetDateTimeProps = {
  site: string;
  date?: string;
  heure?: string;
};

export const TrajetDateTime: FC<TrajetDateTimeProps> = ({
  site,
  date,
  heure,
}) => {
  return (
    <Stack>
      <Typography>
        {date ? dateFormater.getDate(date) : ""} {heure}
      </Typography>
      <Typography
        variant={date ? "caption" : "inherit"}
        color={date ? "secondary" : "inherit"}
        fontWeight={date ? 500 : 400}
      >
        {site}
      </Typography>
    </Stack>
  );
};
