// project import
import dashboard from "./dashboard";
// import transport from "./transport";
import clients from "./clients";
// import facturations from "./facturations";
// import incidents from "./incidents";
import personnels from "./personnels";
import transport from "./transport";
import settings from "./settings";

import ApartmentIcon from "@mui/icons-material/Apartment";
import { EmojiTransportation } from "@mui/icons-material";
import { LINKS } from "utils/constant";

// ==============================|| MENU ITEMS ||============================== //

export const menusPersonnels = [
  dashboard,
  personnels,
  clients,
  transport,
  settings,
  // incidents, facturations
];

export const menusClients = [
  {
    id: "group-clients",
    type: "group",
    children: [
      {
        id: "client",
        title: "Clientèle",
        type: "collapse",
        icon: ApartmentIcon,
        children: [
          {
            id: "client",
            title: "Client",
            type: "item",
            url: "/clients/fiche",
          },
          {
            id: "site",
            title: "Sites",
            type: "item",
            url: "/sites",
          },
        ],
      },
    ],
  },

  {
    id: "group-transport",
    type: "group",
    children: [
      {
        id: "menu.transport.title",
        title: "Transport",
        type: "collapse",
        icon: EmojiTransportation,
        children: [
          {
            id: "desserte",
            title: "Dessertes",
            type: "item",
            url: LINKS.dessertes.list,
          },
          {
            id: "facture",
            title: "Factures",
            type: "item",
            url: LINKS.transportFactures.list,
          },
        ],
      },
    ],
  },
];
