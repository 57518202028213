import { CODE_FONCTION } from "types";

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

const PERSONNELS = "/personnels";
const CLIENTS = "/clients";
const SITES = "/sites";
const VEHICULES = "/vehicules";
const TOURNEES = "/tournees";
const TRANSPORT_FACTURES = "/transport-factures";
const COMMANDES = "/commandes";
const DESSERTES = "/dessertes";
const LIEUX = "/lieux";
const ACTIVITES = "/activites";
const USERS = "/users";

const crudLink = (base: string) => ({
  list: base,
  view: (id: string) => `${base}/${id}/view`,
  edit: (id: string) => `${base}/${id}/edit`,
  create: `${base}/create`,
});

export const LINKS = {
  personnels: {
    ...crudLink(PERSONNELS),
  },
  clients: {
    ...crudLink(CLIENTS),
    fiche: `${CLIENTS}/fiche`,
  },
  sites: {
    ...crudLink(SITES),
  },
  vehicule: {
    ...crudLink(VEHICULES),
  },
  commandes: {
    ...crudLink(COMMANDES),
  },
  dessertes: {
    ...crudLink(DESSERTES),
  },
  tournees: {
    ...crudLink(TOURNEES),
  },
  transportFactures: {
    ...crudLink(TRANSPORT_FACTURES),
  },
  auth: {
    login: "/login",
  },
  users: {
    ...crudLink(USERS),
  },
  settings: {
    base: "/settings",
    villes: "/villes",
    situationGeographiques: "/situationgeographiques",
    pays: "/pays",
    list: "/centresregionaux",
    centresRegionaux: "/centresregionaux",
    centres: "/centres",
    prestations: "/prestations",
    secteurActivites: "/secteuractivites",
  },
  lieux: {
    base: LIEUX,
    pays: `${LIEUX}/pays`,
    villes: `${LIEUX}/villes`,
    situationGeographiques: `${LIEUX}/situationgeographiques`,
    zones: `${LIEUX}/zones`,
    centresRegionaux: `${LIEUX}/centresregionaux`,
    centres: `${LIEUX}/centres`,
    devises: `${LIEUX}/devises`,
  },
  activites: {
    base: ACTIVITES,
    prestations: `${ACTIVITES}/prestations`,
    secteurActivites: `${ACTIVITES}/secteuractivites`,
    services: `${ACTIVITES}/services`,
    fonctions: `${ACTIVITES}/fonctions`,
    joursferies: `${ACTIVITES}/joursferies`,
  },
  home: "/",
};

export const INIT_LIST = {
  data: [],
  total: 0,
  totalPage: 0,
  page: 1,
  totalInDatabase: 0,
};

export const INIT_FILTRE = {
  page: 1,
  perpage: 10,
};

export const DAYS = [
  {
    label: "Lundi",
    value: "lundi",
  },
  {
    label: "Mardi",
    value: "mardi",
  },
  {
    label: "Mercredi",
    value: "mercredi",
  },
  {
    label: "Jeudi",
    value: "jeudi",
  },
  {
    label: "Vendredi",
    value: "vendredi",
  },
  {
    label: "Samedi",
    value: "samedi",
  },
  {
    label: "Dimanche",
    value: "dimanche",
  },
];

export const CORPS = [
  { label: "Gendarmerie", value: "gendarmerie" },
  { label: "Police", value: "police" },
];

export const CODE_CONVOYEUR = [
  CODE_FONCTION.CHEF_DE_BORD,
  CODE_FONCTION.AGENT_DE_GARDE,
  CODE_FONCTION.CHAUFFEUR,
];
