import React, { useState } from "react";

// material-ui
import {
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { authService } from "services";
import { setUser } from "reducers";
import { useNotification } from "hooks";
import { LoadingButton } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { LINKS } from "utils/constant";
import { useDispatch } from "react-redux";
import { parse } from "qs";

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "burval@example.com",
      password: "secret",
    },
  });
  const notification = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const login = (data: Record<string, string>) => {
    setLoading(true);
    authService
      .login(data)
      .then(({ user, roles, access_token }) => {
        setLoading(false);
        dispatch(setUser({ ...user, roles }));
        localStorage.setItem("@burval.token", access_token);
        const { from } = parse(location.search, { ignoreQueryPrefix: true });
        navigate(from?.length ? decodeURI(from as string) : LINKS.home);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          notification.error({
            title: "Echec",
            message: "Email ou mot de passe incorrects",
          });
        } else if (status === 422) {
          notification.error({
            title: "Erreur validation",
            message: "Vos données ne sont pas valides",
          });
        } else {
          notification.error({
            title: "Erreur technique",
            message: "Une erreur technique est survenue lors du traitement",
          });
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(login)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="email">Adresse email</InputLabel>
            <OutlinedInput
              type="email"
              placeholder="Votre adresse email"
              fullWidth
              {...register("email", { required: true })}
            />
            {errors.email && (
              <FormHelperText error>
                {errors.email.message?.toString()}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <InputLabel htmlFor="password">Mot de passe</InputLabel>
            <OutlinedInput
              fullWidth
              error={Boolean(errors.password)}
              id="password"
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                    size="large"
                  >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Votre mot de passe"
            />
            {errors.password && (
              <FormHelperText error id="password">
                {errors.password?.message?.toString()}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            disableElevation
            disabled={false}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
          >
            ME CONNECTER
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
