import { Box, Tab, Tabs } from "@mui/material";
import { FC, useMemo } from "react";

// assets

import { Link, Outlet, useLocation } from "react-router-dom";

interface LinkTabProps {
  label: string;
  to: string;
}

export function LinkTab(props: LinkTabProps) {
  return <Tab component={Link} {...props} />;
}

type LinkProps = {
  label: string;
  to: string;
};

const UtiliataireTemplate: FC<{ links: LinkProps[] }> = ({ links }) => {
  const location = useLocation();

  const value = useMemo(() => {
    const index = links.findIndex((link) => location.pathname === link.to);
    return index < 0 ? 0 : index;
  }, [links, location.pathname]);

  return (
    <>
      <Box component="div" sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          sx={{
            "& .Mui-selected": {
              bgcolor: (theme) => theme.palette.primary.lighter,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
        >
          {links.map((link) => (
            <LinkTab {...link} key={link.to} />
          ))}
        </Tabs>
      </Box>

      <Outlet />
    </>
  );
};

export default UtiliataireTemplate;
