import { FormattedMessage } from "react-intl";

import { NavItemType } from "types/menu";
import ApartmentIcon from "@mui/icons-material/Apartment";

const clients: NavItemType = {
  id: "group-clients",
  type: "group",
  children: [
    {
      id: "menu.clientele.title",
      title: <FormattedMessage id="menu.clientele.title" />,
      type: "collapse",
      icon: ApartmentIcon,
      children: [
        {
          id: "client",
          title: <FormattedMessage id="menu.clientele.client" />,
          type: "item",
          url: "/clients",
          // icon: ApartmentIcon,
        },
        {
          id: "site",
          title: <FormattedMessage id="menu.clientele.site" />,
          type: "item",
          url: "/sites",
          // icon: PublicOutlinedIcon,
        },
      ],
    },
  ],
};

export default clients;
