import { useState } from "react";

// material-ui
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// assets
import { Ballot, Help, Person, PowerSettingsNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { LINKS } from "utils/constant";
import { useUser } from "hooks";
import { isClient } from "utils/user.helpers";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  closeMenu: () => void;
}

const ProfileTab = ({ handleLogout, closeMenu }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();
  const { user } = useUser();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    closeMenu();
  };

  return (
    <List
      component="nav"
      sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 35 } }}
    >
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          handleListItemClick(event, 0);
        }}
      >
        <ListItemIcon>
          <Ballot />
        </ListItemIcon>
        <ListItemText primary="Mon compte" />
      </ListItemButton>

      {!isClient(user) && (
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            handleListItemClick(event, 1);
            navigate(LINKS.users.list);
          }}
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Utilisateurs" />
        </ListItemButton>
      )}

      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 3)
        }
      >
        <ListItemIcon>
          <Help />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
        <ListItemIcon>
          <PowerSettingsNew />
        </ListItemIcon>
        <ListItemText primary="Se deconnecter" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
