import SimpleBar from "components/third-party/SimpleBar";
import { DrawerNavigation } from "./navigation";
import { menusClients, menusPersonnels } from "menu-items";
import { useUser } from "hooks";
import { useMemo } from "react";
import { isClient } from "utils/user.helpers";

export const DrawerContent = () => {
  const { user } = useUser();

  const menuItems = useMemo(() => {
    if (isClient(user)) {
      return menusClients;
    }
    return menusPersonnels;
  }, [user]);

  return (
    <SimpleBar
      sx={{
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DrawerNavigation menuItems={menuItems} />
    </SimpleBar>
  );
};
