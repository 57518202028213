import { endOfMonth } from "date-fns";
import { REFERENTIEL_TYPE, RequestParam, UserResource } from "types";
import {
  CentreRegionalResource,
  CentreResource,
  PaysResource,
  VilleResource,
  SituationGeographiqueResource,
  DeviseResource,
} from "types/centre.type";
import { SelectItem } from "types/form.type";
import { PersonnelResource } from "types/personnel.type";
import { ReferentielResource } from "types/referentiel.type";
import { FonctionResource, ServiceResource } from "types/service.type";
import { VehiculeResource } from "types/transport.types";
import { dateParser } from "./date";
import { get } from "lodash";

export const buildReferentielItem = (
  data: ReferentielResource[],
  type?: REFERENTIEL_TYPE
) => {
  return data
    .filter((item) => item.type === type)
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));
};

export const getSelectValue = (item?: SelectItem) => {
  return item?.value;
};

export const buildSelectItem = (label: string, value: string) => {
  return { label, value };
};

export const selectHelper = {
  getValue: (item?: SelectItem) => {
    return item?.value || null;
  },
  build: (label?: string, value?: string): SelectItem | undefined | null => {
    if (value === undefined || value === null || !label) {
      return null;
    }
    return { label, value };
  },
  buildPersonnel: (personnel?: PersonnelResource) => {
    return personnel
      ? selectHelper.build(
          `${personnel.firstname} ${personnel.lastname}`,
          personnel.id
        )
      : null;
  },
  buildReferentiel: (data?: ReferentielResource) => {
    return selectHelper.build(data?.name, data?.id);
  },
  buildPays: (data?: PaysResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildDevise: (data?: DeviseResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildVille: (data?: VilleResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildCentre: (data?: CentreResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildCentreRegional: (data?: CentreRegionalResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildService: (data?: ServiceResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildFonction: (data?: FonctionResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildSituationGeographique: (data?: SituationGeographiqueResource) => {
    return selectHelper.build(data?.libelle, data?.id);
  },
  buildVehicule: (data?: VehiculeResource) => {
    return selectHelper.build(data?.marque, data?.id);
  },
};

export const numberHelper = {
  validation: {
    test: (value?: string | null) => {
      if (value === undefined) {
        return false;
      }
      const _value = value?.trim() || "";
      return _value === "" ? true : !isNaN(parseInt(_value));
    },
    message: "Valeur incorrecte",
  },
  trim: (montant?: string) =>
    montant ? montant.toString().replaceAll(" ", "") : montant,

  parseInt: (value?: string) => {
    return parseInt(numberHelper.trim(value) || "") || 0;
  },
};

export const requestParams = (params?: RequestParam) => {
  const entries = Object.entries(params || {}).filter(
    (entry) => entry[1] !== undefined && entry[1] !== null
  );
  if (entries.length > 0) {
    return `?${entries.map((entry) => `${entry[0]}=${entry[1]}`).join("&")}`;
  }
  return "";
};

export const ETAT_COLOR: Record<string, any> = {
  livre: "success",
  en_cours: "info",
  init: "warning",
  paye: "success",
  non_paye: "warning",
  annule: "error",
};

export const getEtatColor = (etat: ReferentielResource): any => {
  return ETAT_COLOR[etat.id || (etat as any).value] || "default";
};

export const uuid = () =>
  String(Date.now().toString(32) + Math.random().toString(16)).replace(
    /\./g,
    ""
  );

export function updateElement(list: any[], element: any, index: number) {
  return [...list.slice(0, index), element, ...list.slice(index + 1)];
}

export function addOrUpdateElement(list: any[], element: any, index: number) {
  return index < 0
    ? [...list, element]
    : [...list.slice(0, index), element, ...list.slice(index + 1)];
}

export function deleteElement(list: any[], index: number) {
  return [...list.slice(0, index), ...list.slice(index + 1)];
}

export function addElement(list: any[], element: any) {
  return [...list, element];
}

export const buildUserFullName = (user?: UserResource) => {
  if (!user) {
    return "";
  }

  return `${user.firstname} ${user.lastname}`;
};

export const buildUserShortName = (user?: UserResource) => {
  if (!user) {
    return "";
  }
  return `${user.lastname ? user.lastname[0] : ""}. ${user.firstname}`;
};

export const libelleBuilder = {
  buildRegime: (intramuros?: number) => {
    return intramuros ? "Intra muros" : "Extra muros";
  },
};

export const isIntraMuros = (value: string) => {
  return value === "1";
};

type IComputeMontant = {
  montantKmVb?: number;
  kmVoieBitumee?: number;
  montantKmVnb?: number;
  kmVoieNonBitumee?: number;
};

export const desserteHelper = {
  computeMontant: (param: IComputeMontant) => {
    return (
      (param.montantKmVb || 0) * (param.kmVoieBitumee || 0) +
      (param.montantKmVnb || 0) * (param.kmVoieNonBitumee || 0)
    );
  },
  isFacturationMensuel: (mode: string) => "0" === mode,
  isFacturationParDesserte: (mode: string) => "1" === mode,
  isFacturationPourcentage: (mode: string) => "2" === mode,

  isExtraMuros: (mode?: string) => "0" === mode,
  isIntraMuros: (mode?: string) => "1" === mode,

  isDepot: (type?: string) => "depot" === type,

  getSiteNameByTypeDesserte: (type?: string) => {
    return desserteHelper.isDepot(type)
      ? "Site d’expédition"
      : "Site destination";
  },

  getLibelleModeFacture: (modeFacture?: number) => {
    switch (modeFacture?.toString()) {
      case "0":
        return "Mensuel";
      case "2":
        return "Pourcentage colis";
      default:
        return "Par desserte";
    }
  },

  getLibelleRegime: (intramuros?: number) => {
    return intramuros ? "Intra muros" : "Extra muros";
  },
};

export const getNombreDeJours = (mois?: any, annee?: any) => {
  const periode = dateParser.toDate(`1/${mois}/${annee}`) || new Date();
  return endOfMonth(periode).getDate();
};

export function getJsonValue(obj: Record<string, any>, key: string) {
  return get(obj, key);
}
