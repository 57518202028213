// material-ui
import { Theme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

// import Message from './Message';
import Profile from "./profile";
// import Localization from './Localization';
// import Notification from './Notification';
// import Customization from './Customization';
import MobileSection from "./MobileSection";
// import MegaMenuSection from './MegaMenuSection';

// ==============================|| HEADER - CONTENT ||============================== //

export const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <>
      {/* {!matchesXs && <Search />} */}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}

      {/* <Notification /> */}
      {/* <Message /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};
