import {
  Skeleton,
  SxProps,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import { FC, Fragment, ReactNode } from "react";
import {
  useExpanded,
  useGlobalFilter,
  useTable,
  usePagination,
  Column,
} from "react-table";
import { HeaderSort, TablePagination } from "components/third-party/ReactTable";
import { FilterProps } from "types/list.type";
import MainCard from "components/MainCard";

type TableProps = {
  columns: Column[];
  data: any[];
  loading?: boolean;
  filter?: FilterProps;
  total?: number;
  totalPage?: number;
  totalInDatabase?: number;
  setFilter?: (filter: FilterProps) => void;
  renderRowSubComponent?: (row: any) => ReactNode;
  mainCardSx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
  size?: "small" | "medium";
  emptyContent?: ReactNode;
};

export const Table: FC<TableProps> = ({
  columns,
  data,
  renderRowSubComponent,
  loading,
  total,
  totalPage,
  totalInDatabase,
  filter,
  setFilter,
  mainCardSx,
  headerSx,
  size = "medium",
  emptyContent = "Aucun résultat",
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        //@ts-ignore
        manualPagination: true,
      },
      useGlobalFilter,
      useExpanded,
      usePagination
    );

  const renderContent = () => {
    if (loading) {
      return (
        <TableBody>
          {new Array(filter?.perpage || 10).fill(1).map((_, index) => (
            <TableRow key={index}>
              {new Array(columns.length).fill(1).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      );
    }

    if (!data.length) {
      return (
        <TableBody {...getTableBodyProps()}>
          <TableRow
            sx={{
              "&:hover": { bgcolor: "transparent !important" },
            }}
          >
            <TableCell
              sx={{ p: 2, py: 3, textAlign: "center" }}
              colSpan={columns.length}
            >
              {emptyContent}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody {...getTableBodyProps()}>
        {rows.map((row: any, i: number) => {
          prepareRow(row);

          return (
            <Fragment key={i}>
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell: any) => (
                  <TableCell
                    {...cell.getCellProps([
                      { className: cell.column.className },
                    ])}
                  >
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
              {row.isExpanded && renderRowSubComponent
                ? renderRowSubComponent(row)
                : null}
            </Fragment>
          );
        })}
        {totalPage && (
          <TableRow
            sx={{
              "&:hover": { bgcolor: "transparent !important" },
            }}
          >
            <TableCell sx={{ p: 2, py: 3 }} colSpan={columns.length}>
              <TablePagination
                setPageSize={(perpage) => {
                  setFilter && setFilter({ ...filter, perpage, page: 1 });
                }}
                gotoPage={(page) => {
                  setFilter && setFilter({ ...filter, page });
                }}
                pageSize={filter?.perpage || 10}
                pageIndex={filter?.page ? filter.page - 1 : 0}
                totalPage={totalPage || 0}
                total={total || 0}
                totalInDatabase={totalInDatabase || 0}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <>
      <MainCard elevation={0} content={false} sx={mainCardSx}>
        <MuiTable size={size} {...getTableProps()}>
          <TableHead sx={headerSx}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    {...column.getHeaderProps([
                      { className: column.className },
                    ])}
                  >
                    <HeaderSort column={column} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {renderContent()}
        </MuiTable>
      </MainCard>
    </>
  );
};
