import { Stack, Typography } from "@mui/material";
import { useUser } from "hooks";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { centreService } from "services";
import { RequestParam } from "types";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import {
  AutoCompleteAsync,
  AutoCompleteAsyncHookForm,
  FetchDataProps,
} from "../AutoComplete";

interface CentreItem extends SelectItem {
  centreRegional: string;
}

type LoadDataProps = {
  centreRegional?: SelectItem;
};

const useLoadData = ({ centreRegional }: LoadDataProps) => {
  const { paysId } = useUser();

  const fetchData = async ({ page }: FetchDataProps) => {
    const value = centreRegional?.value;
    const params: RequestParam = { page, paysId };

    if (centreRegional?.value) {
      params.centreRegionalId = value;
    }
    const { data, total, totalPage } = await centreService.findAll(params);
    return {
      data: data.map(({ id, libelle, centreRegional }) => ({
        label: libelle,
        value: id,
        centreRegional: centreRegional.libelle,
        paysId,
      })),
      hasMore: total < totalPage,
    };
  };

  const resetDependencies = useMemo(() => {
    return centreRegional ? [centreRegional] : [];
  }, [centreRegional]);

  return { fetchData, resetDependencies };
};

const renderOption = (_: any, _option: any) => {
  const option = _option as CentreItem;
  return (
    <Stack>
      <Typography variant="subtitle1">{option.label}</Typography>
      <Typography color="secondary" variant="caption">
        {option.centreRegional}
      </Typography>
    </Stack>
  );
};

interface CentreAsyncSelectFormProps extends SelectAsyncProps {
  centreRegionalField: string;
}

export const CentreAsyncSelectForm: FC<CentreAsyncSelectFormProps> = ({
  centreRegionalField,
  ...rest
}) => {
  const { watch } = useFormContext();
  const centreRegional = watch(centreRegionalField);

  const loadData = useLoadData({ centreRegional });

  return (
    <AutoCompleteAsyncHookForm
      {...rest}
      {...loadData}
      renderOption={renderOption}
    />
  );
};

export const SelectCentreAsync: FC<SelectAsyncProps> = (props) => {
  const loadData = useLoadData({});

  return (
    <AutoCompleteAsync
      variant="standard"
      {...props}
      {...loadData}
      renderOption={renderOption}
    />
  );
};
