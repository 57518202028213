import { Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { useUser } from "hooks";
import { FC } from "react";
import { clientService, siteService } from "services";
import { CentreResource } from "types/centre.type";
import { ClientResource } from "types/client.type";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm, FetchDataProps } from "../AutoComplete";

interface ClientItem extends SelectItem {
  logo?: string;
  numero: string;
  heureDebutPassage?: string;
  heureFinPassage?: string;
}

interface SiteItem extends SelectItem {
  client?: ClientResource;
  centre?: CentreResource;
}

export const SelectClientAsyncForm: FC<SelectAsyncProps> = (props) => {
  const { paysId } = useUser();
  const fetchData = async (param: FetchDataProps) => {
    const { data, page, totalPage } = await clientService.findAll({
      size: 100,
      ...param,
      paysId,
    });
    return {
      data: data.map((item) => ({
        label: item.nom,
        value: item.id,
        ...item,
      })),
      hasMore: page < totalPage,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      {...props}
      fetchData={fetchData}
      renderOption={(optionProps, _option) => {
        const option = _option as ClientItem;
        return (
          <>
            <Avatar
              src={option.logo}
              sx={{ marginRight: 1, color: "textPrimary" }}
              resource="client"
            />
            <Stack>
              <Typography variant="subtitle1">{option.label}</Typography>
              <Typography color="secondary" variant="caption">
                {option.numero}
              </Typography>
            </Stack>
          </>
        );
      }}
    />
  );
};

export const SelectSiteAsyncForm: FC<SelectAsyncProps> = (props) => {
  const { paysId } = useUser();

  const fetchData = async (param: FetchDataProps) => {
    const { data, page, totalPage } = await siteService.findAll({
      size: 100,
      ...param,
      paysId,
    });
    return {
      data: data.map(({ id, nom, ...rest }) => ({
        label: nom,
        value: id,
        ...rest,
      })),
      hasMore: page < totalPage,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      {...props}
      fetchData={fetchData}
      renderOption={(optionProps, _option) => {
        const option = _option as SiteItem;
        return (
          <Stack>
            <Typography variant="subtitle1">{option.label}</Typography>
            <Typography color="secondary" variant="caption">
              {option.client?.nom}
            </Typography>
          </Stack>
        );
      }}
    />
  );
};
