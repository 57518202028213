import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUser, selectUserPays } from "reducers";
import { isClient } from "utils/user.helpers";

export const useUser = () => {
  const user = useSelector(selectUser);
  const paysId = useSelector(selectUserPays);

  if (!user) {
    throw new Error("Pas d'utilisateur");
  }

  const requestParams = useMemo(() => {
    const params: Record<string, any> = { paysId };
    if (isClient(user)) {
      params.clientId = user.userableId;
    }
    return params;
  }, [user, paysId]);

  return {
    paysId: user.pays?.id,
    user,
    requestParams,
  };
};
