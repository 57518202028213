import { Settings } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";
import { LINKS } from "utils/constant";

const settings: NavItemType = {
  id: "settings",
  type: "group",
  children: [
    {
      id: "utilitaires",
      type: "collapse",
      title: <FormattedMessage id="menu.setting.title" />,
      icon: Settings,
      children: [
        {
          id: "lieux",
          title: <FormattedMessage id="menu.setting.lieux.title" />,
          type: "item",
          url: LINKS.lieux.base,
        },
        {
          id: "activites",
          title: <FormattedMessage id="menu.setting.activites.title" />,
          type: "item",
          url: LINKS.activites.base,
        },
      ],
    },
  ],
};

export default settings;
