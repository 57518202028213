import { requestBlob } from "./request";
import fileDownload from "js-file-download";

export const exportService = {
  pdf: (path: string) => {
    return requestBlob(`${path}?format=pdf`).then((response) => {
      const filename =
        response.headers?.get("Content-Disposition")?.split("filename=")[1] ||
        "document.pdf";

      return response.blob().then((blob: any) => {
        fileDownload(blob, filename);
      });
    });
  },
};
