import { FC } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { useFormContext } from "react-hook-form";

type DateInputProps = { name: string } & TextFieldProps;

function withDate<T>(Wrapper: any, dateProps: any) {
  const Hoc: FC<DateInputProps & { dateWrapperProps?: T }> = ({
    name,
    label,
    variant = "outlined",
    fullWidth = true,
    disabled = false,
    size = "small",
    dateWrapperProps = {},
    ...rest
  }) => {
    const {
      formState: { errors },
      watch,
      setValue,
      clearErrors,
    } = useFormContext();

    return (
      <Wrapper
        value={watch(name) || null}
        {...dateProps}
        onChange={(value: any) => {
          setValue(name, value);
          clearErrors(name);
        }}
        {...(dateWrapperProps || {})}
        ampm={false}
        label={label}
        disabled={disabled}
        renderInput={(params: any) => (
          <TextField
            fullWidth
            {...rest}
            {...params}
            variant={variant}
            name={name}
            error={!!errors[name]}
            helperText={errors[name]?.message?.toString() || ""}
            size={size}
          />
        )}
      />
    );
  };
  return Hoc;
}

export const DateInput = withDate<any>(DesktopDatePicker, {
  inputFormat: "dd/MM/yyyy",
});

export const TimeInput = withDate(TimePicker, { inputFormat: "HH:mm" });

export const MonthYearInput = withDate(DesktopDatePicker, {
  views: ["month", "year"],
  inputFormat: "MM/yyyy",
});

export const MonthInput = withDate(DesktopDatePicker, {
  views: ["month"],
  inputFormat: "MM",
});

export const YearInput = withDate(DesktopDatePicker, {
  views: ["year"],
  inputFormat: "yyyy",
});
