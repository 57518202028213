import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { MoreActionItem } from "types/menu";

const MoreAction: FC<{ handleClose: () => void } & MoreActionItem> = ({
  title,
  subtitle,
  handleClose,
  Icon,
  disabled,
}) => {
  return (
    <MenuItem disabled={disabled} onClick={handleClose} sx={{ paddingY: 1.25 }}>
      {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
      <ListItemText
        primary={<Typography variant="subtitle1">{title}</Typography>}
        secondary={
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        }
      />
    </MenuItem>
  );
};

type MoreActionsProps = {
  actions: MoreActionItem[];
  onSelected: (action: MoreActionItem) => void;
};

export const MoreActions: FC<MoreActionsProps> = ({ actions, onSelected }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} color="primary">
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={3}
        sx={{
          ".MuiMenu-list": {
            paddingTop: 0,
          },
        }}
      >
        <Grow in>
          <div>
            <Box
              padding={1.5}
              sx={{ bgcolor: (t) => t.palette.background.default }}
            >
              <Typography variant="subtitle1">Menu</Typography>
            </Box>
            <Divider />
            {actions.map((action) => (
              <React.Fragment key={action.code}>
                <MoreAction
                  handleClose={() => {
                    handleClose();
                    onSelected(action);
                  }}
                  {...action}
                />
              </React.Fragment>
            ))}
          </div>
        </Grow>
      </Menu>
    </div>
  );
};
