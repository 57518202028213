import {
  DesserteResource,
  TourneeItem,
  TourneeResource,
} from "types/transport.types";
import { CrudService } from "./crudService";
import { requestGet, requestPost, requestPut } from "./request";

const base = "tournees";

const crudService = new CrudService<TourneeItem, TourneeResource>(base);

export const tourneeService = {
  ...crudService,
  duplicate: (tourneeId: string, body: any) => {
    return requestPost<{ data: TourneeResource }>(
      `${base}/${tourneeId}/duplicate`,
      body
    );
  },
  demarrer: (tourneeId: string) => {
    return requestPut<{ data: TourneeItem }>(`${base}/${tourneeId}/demarrer`);
  },
  terminer: (tourneeId: string) => {
    return requestPut<{ data: TourneeItem }>(`${base}/${tourneeId}/terminer`);
  },
  findDessertes: (tourneeId: string) => {
    return requestGet<{ data: DesserteResource[] }>(
      `${base}/${tourneeId}/dessertes`
    );
  },
  modifierEquipages: (tourneeId: string, body: any) => {
    return requestPut<{ data: DesserteResource[] }>(
      `${base}/${tourneeId}/modifier-equipages`,
      body
    );
  },
  changerEquipage: (tourneeId: string, body: any) => {
    return requestPut<{ data: DesserteResource[] }>(
      `${base}/${tourneeId}/changer-equipage`,
      body
    );
  },

  affecterDessertes: (tourneeId: string, body: any) => {
    return requestPut<{ data: DesserteResource[] }>(
      `${base}/${tourneeId}/affecter-dessertes`,
      body
    );
  },
};
