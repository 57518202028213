import { FormattedMessage } from "react-intl";

import { People } from "@mui/icons-material";
import { NavItemType } from "types/menu";

const personnels: NavItemType = {
  id: "group-personnels",
  type: "group",
  children: [
    {
      id: "personnel",
      title: <FormattedMessage id="menu.personnel.title" />,
      type: "item",
      url: "/personnels",
      icon: People,
    },
  ],
};

export default personnels;
