import { useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";

// project import
import Avatar from "components/@extended/Avatar";
import Transitions from "components/@extended/Transitions";
import MainCard from "components/MainCard";
import ProfileTab from "./ProfileTab";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "reducers";
import { buildUserFullName, buildUserShortName } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { LINKS } from "utils/constant";

// assets
// import avatar1 from "assets/images/users/avatar-1.png";

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const user = useSelector(selectUser)!;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      // await logout();
      localStorage.removeItem("@burval.token");
      dispatch(setUser(undefined));
      navigation(LINKS.auth.login);
    } catch (err) {
      console.error(err);
    }
  };

  const iconBackColorOpen =
    theme.palette.mode === "dark" ? "grey.200" : "grey.300";

  return (
    <Box sx={{ flexShrink: 0, ml: "auto" }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          "&:hover": {
            bgcolor:
              theme.palette.mode === "dark"
                ? "secondary.light"
                : "secondary.lighter",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" size="sm" color="primary" />
          <Typography variant="subtitle1">
            {buildUserShortName(user)}
          </Typography>
        </Stack>
      </ButtonBase>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 250,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5 }}>
                      <Stack direction="row" spacing={1.25} alignItems="center">
                        <Avatar
                          alt="profile user"
                          sx={{ width: 40, height: 40 }}
                        />
                        <Stack>
                          <Typography variant="subtitle1">
                            {buildUserFullName(user)}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {user?.roles
                              ?.map((role) => role.libelle)
                              ?.join(" , ") || "Aucun rôle"}
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                    <Divider />
                    {open && (
                      <ProfileTab
                        closeMenu={() => setOpen(false)}
                        handleLogout={handleLogout}
                      />
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
