import { FormattedMessage } from "react-intl";
import { NavItemType } from "types/menu";

import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import { LINKS } from "utils/constant";

const transport: NavItemType = {
  id: "group-transport",
  type: "group",
  children: [
    {
      id: "menu.transport.title",
      title: <FormattedMessage id="menu.transport.title" />,
      type: "collapse",
      icon: EmojiTransportationIcon,
      children: [
        {
          id: "vehicule",
          title: <FormattedMessage id="menu.transport.vehicule.title" />,
          type: "item",
          url: LINKS.vehicule.list,
        },
        {
          id: "desserte",
          title: <FormattedMessage id="menu.transport.desserte.title" />,
          type: "item",
          url: LINKS.dessertes.list,
        },
        {
          id: "tournee",
          title: <FormattedMessage id="menu.transport.tournee.title" />,
          type: "item",
          url: LINKS.tournees.list,
        },
        {
          id: "facture",
          title: "Factures",
          type: "item",
          url: LINKS.transportFactures.list,
        },
      ],
    },
  ],
};

export default transport;
