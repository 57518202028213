import { Alert } from "@mui/material";
import { Loadable } from "components/Loadable";
import { MainLayout } from "layout";
import { AuthLogin } from "pages";
import { HomePage } from "pages/home/HomePage";
import ListCentre from "pages/utilitaires/centre/ListCentre";
import ListCentreRegional from "pages/utilitaires/centreregional/ListCentreRegional";
import ListDevise from "pages/utilitaires/devise/ListDevise";
import ListFerie from "pages/utilitaires/feries/ListFerie";
import ListFonction from "pages/utilitaires/fonction/ListFonction";
import ListPays from "pages/utilitaires/pays/ListPays";
import ListPrestation from "pages/utilitaires/prestations/ListPrestation";
import ListSecteurActivite from "pages/utilitaires/secteuractivite/ListSecteurActivite";
import ListService from "pages/utilitaires/service/ListService";
import ListSituationGeographique from "pages/utilitaires/situationGeographique/ListSituationGeographique";
import ListVille from "pages/utilitaires/villes/ListVille";
import ListZone from "pages/utilitaires/zone/ListZone";
import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { LINKS } from "utils/constant";
import { ActiviteNavigation } from "./ActiviteNavigation";
import { LieuNavigation } from "./LieuNavigation";

// Personnels
const ListPersonnel = Loadable(
  lazy(() => import("pages/personnels/ListPersonnel"))
);
const CreatePersonnel = Loadable(
  lazy(() => import("pages/personnels/CreatePersonnel"))
);
const ViewPersonnel = Loadable(
  lazy(() => import("pages/personnels/ViewPersonnel"))
);
const EditPersonnel = Loadable(
  lazy(() => import("pages/personnels/EditPersonnel"))
);

// Clients
const ListClient = Loadable(lazy(() => import("pages/clients/ListClient")));
const CreateClient = Loadable(lazy(() => import("pages/clients/CreateClient")));
const ViewClient = Loadable(lazy(() => import("pages/clients/ViewClient")));
const EditClient = Loadable(lazy(() => import("pages/clients/EditClient")));
const FicheClient = Loadable(lazy(() => import("pages/clients/FicheClient")));

// Sites
const ListSite = Loadable(lazy(() => import("pages/sites/ListSite")));
const CreateSite = Loadable(lazy(() => import("pages/sites/CreateSite")));
const EditSite = Loadable(lazy(() => import("pages/sites/EditSite")));
const ViewSite = Loadable(lazy(() => import("pages/sites/ViewSite")));

// Vehicules
const ListVehicule = Loadable(
  lazy(() => import("pages/vehicules/ListVehicule"))
);
const ViewVehicule = Loadable(
  lazy(() => import("pages/vehicules/ViewVehicule"))
);
const CreateVehicule = Loadable(
  lazy(() => import("pages/vehicules/CreateVehicule"))
);
const EditVehicule = Loadable(
  lazy(() => import("pages/vehicules/EditVehicule"))
);

// Dessertes
const ListDesserte = Loadable(
  lazy(() => import("pages/dessertes/ListDesserte"))
);
const CreateDesserte = Loadable(
  lazy(() => import("pages/dessertes/CreateDesserte"))
);
const ViewDesserte = Loadable(
  lazy(() => import("pages/dessertes/ViewDesserte"))
);

//Tournees
const ListTournee = Loadable(lazy(() => import("pages/tournees/ListTournee")));
const CreateTournee = Loadable(
  lazy(() => import("pages/tournees/CreateTournee"))
);
const ViewTournee = Loadable(lazy(() => import("pages/tournees/ViewTournee")));

// Users
const ListUser = Loadable(
  lazy(() => import("pages/utilitaires/users/ListUser"))
);
const CreateUser = Loadable(
  lazy(() => import("pages/utilitaires/users/CreateUser"))
);

// Facture
const ListFactureTransport = Loadable(
  lazy(() => import("pages/transport/factures/ListFactureTransport"))
);

const CreateFactureTransport = Loadable(
  lazy(() => import("pages/transport/factures/CreateFactureTransport"))
);
const ViewFactureTransport = Loadable(
  lazy(() => import("pages/transport/factures/ViewTransportFacture"))
);

export const Navigation = () => {
  return (
    <Routes>
      <Route path="/login" element={<AuthLogin />} />
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<HomePage />} />

        {/* navigation personnels */}
        <Route path="/personnels" element={<Outlet />}>
          <Route index element={<ListPersonnel />} />
          <Route path="create" element={<CreatePersonnel />} />
          <Route path=":id/view" element={<ViewPersonnel />} />
          <Route path=":id/edit" element={<EditPersonnel />} />
        </Route>

        {/* navigation clients */}
        <Route path="/clients" element={<Outlet />}>
          <Route index element={<ListClient />} />
          <Route path="create" element={<CreateClient />} />
          <Route path=":id/view" element={<ViewClient />} />
          <Route path=":id/edit" element={<EditClient />} />
          <Route path="fiche" element={<FicheClient />} />
        </Route>

        {/* navigation sites */}
        <Route path="/sites" element={<Outlet />}>
          <Route index element={<ListSite />} />
          <Route path="create" element={<CreateSite />} />
          <Route path=":id/view" element={<ViewSite />} />
          <Route path=":id/edit" element={<EditSite />} />
        </Route>

        {/* navigation vehicules */}
        <Route path={LINKS.vehicule.list} element={<Outlet />}>
          <Route index element={<ListVehicule />} />
          <Route path="create" element={<CreateVehicule />} />
          <Route path=":id/view" element={<ViewVehicule />} />
          <Route path=":id/edit" element={<EditVehicule />} />
        </Route>

        {/* navigation tournees */}
        <Route path={LINKS.tournees.list} element={<Outlet />}>
          <Route index element={<ListTournee />} />
          <Route path="create" element={<CreateTournee />} />
          <Route path=":id/view" element={<ViewTournee />} />
        </Route>

        {/* navigation dessertes */}
        <Route path={LINKS.dessertes.list} element={<Outlet />}>
          <Route index element={<ListDesserte />} />
          <Route path="create" element={<CreateDesserte />} />
          <Route path=":id/view" element={<ViewDesserte />} />
          {/* <Route path=":id/edit" element={<EditCommande />} /> */}
        </Route>

        {/* navigation users */}
        <Route path={LINKS.users.list} element={<Outlet />}>
          <Route index element={<ListUser />} />
          <Route path="create" element={<CreateUser />} />
        </Route>

        <Route path={LINKS.lieux.base} element={<LieuNavigation />}>
          <Route index element={<ListPays />} />
          <Route path={LINKS.lieux.pays} element={<ListPays />} />
          <Route path={LINKS.lieux.villes} element={<ListVille />} />
          <Route
            path={LINKS.lieux.centresRegionaux}
            element={<ListCentreRegional />}
          />
          <Route path={LINKS.lieux.centres} element={<ListCentre />} />
          <Route
            path={LINKS.lieux.situationGeographiques}
            element={<ListSituationGeographique />}
          />
          <Route path={LINKS.lieux.zones} element={<ListZone />} />
          <Route path={LINKS.lieux.devises} element={<ListDevise />} />
        </Route>

        <Route path={LINKS.activites.base} element={<ActiviteNavigation />}>
          <Route index element={<ListPrestation />} />
          <Route
            path={LINKS.activites.secteurActivites}
            element={<ListSecteurActivite />}
          />
          <Route
            path={LINKS.activites.prestations}
            element={<ListPrestation />}
          />
          <Route path={LINKS.activites.services} element={<ListService />} />
          <Route path={LINKS.activites.fonctions} element={<ListFonction />} />
          <Route path={LINKS.activites.joursferies} element={<ListFerie />} />
        </Route>

        <Route path={LINKS.transportFactures.list} element={<Outlet />}>
          <Route index element={<ListFactureTransport />} />
          <Route
            path={LINKS.transportFactures.create}
            element={<CreateFactureTransport />}
          />
          <Route path=":id/view" element={<ViewFactureTransport />} />
        </Route>

        <Route
          path="*"
          element={
            <Alert variant="filled" color="error">
              Cette page n'est pas disponible
            </Alert>
          }
        />
      </Route>
    </Routes>
  );
};
