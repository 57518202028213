import { Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import { useUser } from "hooks";
import { FC } from "react";
import { personnelService } from "services";
import { RequestParam } from "types";
import { SelectItem } from "types/form.type";
import { SelectAsyncProps } from "types/select-async.type";
import { AutoCompleteAsyncHookForm, FetchDataProps } from "../AutoComplete";

interface PersonnelItem extends SelectItem {
  matricule: string;
  photo?: string;
  firstname: string;
  lastname: string;
  email: string;
}

interface SelectPersonnelAsyncFormProps extends SelectAsyncProps {
  params?: RequestParam;
}

export const SelectPersonnelAsyncForm: FC<SelectPersonnelAsyncFormProps> = ({
  label,
  name,
  params,
  ...rest
}) => {
  const { paysId } = useUser();

  const fetchData = async (param: FetchDataProps) => {
    const { data, page, totalPage } = await personnelService.findAll({
      size: 100,
      ...param,
      paysId,
      ...(params || {}),
    });
    return {
      data: data.map((item) => ({
        label: `${item.lastname} ${item.firstname}`,
        value: item.id,
        matricule: item.matricule,
        photo: item.photo,
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
      })),
      hasMore: page < totalPage,
    };
  };

  return (
    <AutoCompleteAsyncHookForm
      label={label}
      name={name}
      fetchData={fetchData}
      {...rest}
      renderOption={(props, _option) => {
        const option = _option as PersonnelItem;
        return (
          <>
            <Avatar
              src={option.photo}
              resource="personne"
              color="primary"
              sx={{ marginRight: 1, color: "textPrimary" }}
            />
            <Stack>
              <Typography variant="subtitle1">{option.label}</Typography>
              <Typography color="secondary" variant="caption">
                {option.matricule}
              </Typography>
            </Stack>
          </>
        );
      }}
    />
  );
};
