import { Grid } from "@mui/material";
import { TextInput } from "components";
import { withForm, WrapperProps } from "hoc";
import { FC } from "react";
import { UtilitaireModal } from "../UtilitaireModal";
import { CentreAsyncSelectForm } from "components";

const Form: FC<WrapperProps> = (props) => {
  return (
    <UtilitaireModal
      {...props}
      onClose={props.onBack}
      title={props.isEditMode ? "Gestion de la ville" : "Créer une ville"}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextInput label="Nom *" name="libelle" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Code" name="code" disabled />
        </Grid>
        <Grid item xs={12}>
          <CentreAsyncSelectForm label="Centre *" name="centreId" centreRegionalField="" />
        </Grid>
      </Grid>
    </UtilitaireModal>
  );
};

export const VilleModal = withForm(Form);
